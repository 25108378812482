import WrapPageElement from "components/wrapPageElement"
import WrapRootElement from "components/wrapRootElement"
import * as React from "react"

export const wrapPageElement  = ({element,props }) => {
  return (
    <WrapPageElement>
      {element}
    </WrapPageElement>
  )
}

export const wrapRootElement  = ({element,props }) => {
  return (
    <WrapRootElement>
      {element}
    </WrapRootElement>
  )
}