import * as React from "react"
import { forwardRef } from "react"

const SvgTimevit = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 198.16 45.57"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".timevit_svg__cls-1{fill:#231f20}"}</style>
    </defs>
    <g id="timevit_svg__Layer_2" data-name="Layer 2">
      <g id="timevit_svg__Layer_1-2" data-name="Layer 1">
        <path
          className="timevit_svg__cls-1"
          d="M0 7.71h11.97v37.17h6.36V7.71h12V1.82H0v5.89zM34.92 1.09a3.69 3.69 0 0 0-1.12 2.75 3.71 3.71 0 0 0 1.12 2.74 3.93 3.93 0 0 0 5.43 0 3.67 3.67 0 0 0 1.13-2.7 3.67 3.67 0 0 0-1.14-2.75 3.91 3.91 0 0 0-5.42-.04ZM34.43 13.71h6.24v31.17h-6.24zM81.61 13a11.08 11.08 0 0 0-9.72 5.22 9.1 9.1 0 0 0-2.79-3.37A9.33 9.33 0 0 0 63.35 13a10.61 10.61 0 0 0-8.6 4v-3.29h-6.23v31.17h6.23V27.21a10.15 10.15 0 0 1 1.88-6.38 5.49 5.49 0 0 1 4.57-2.33c4 0 6 2.62 6 8v18.38h6.24V27.21a10 10 0 0 1 2-6.22A5.58 5.58 0 0 1 80 18.5c2.15 0 3.63.61 4.5 1.88s1.39 3.59 1.39 6.71v17.79h6.24v-19C92.15 17.33 88.6 13 81.61 13ZM111.75 13a13.35 13.35 0 0 0-10.3 4.61 17 17 0 0 0-4.12 11.79c0 5.05 1.27 9.05 3.78 11.87s6 4.29 10.44 4.29 8.06-1 10.79-3l.3-.22V36l-1.2.94a13.9 13.9 0 0 1-8.76 3.15 8.42 8.42 0 0 1-6.48-2.47 10 10 0 0 1-2.43-6.4h20.93V28c0-4.63-1.13-8.31-3.37-10.95S115.85 13 111.75 13Zm-.08 5.49a6 6 0 0 1 4.87 2 8.5 8.5 0 0 1 1.78 5.18h-14.38a9.82 9.82 0 0 1 2.55-5 6.88 6.88 0 0 1 5.18-2.17ZM140.59 35.89c-.12.32-.22.65-.32 1-.11-.36-.24-.73-.37-1.11l-7.71-22.06h-6.82l11.81 31.17h5.68l12.42-31.17h-6.63ZM158.25 13.71h6.24v31.17h-6.24zM158.73 1.09a3.73 3.73 0 0 0-1.12 2.75 3.68 3.68 0 0 0 1.13 2.74 3.93 3.93 0 0 0 5.43 0 3.67 3.67 0 0 0 1.13-2.7 3.67 3.67 0 0 0-1.14-2.75 3.92 3.92 0 0 0-5.43-.04ZM187.59 19.25v-5.54h-7.48V4.65l-6.24 2v7.05h-5.1v5.54h5.1v16.84c0 6.17 2.95 9.44 8.54 9.44a9.93 9.93 0 0 0 4.8-1l.38-.21v-6l-1.19.89a4.21 4.21 0 0 1-2.66.84A3.39 3.39 0 0 1 181 39a6.41 6.41 0 0 1-.85-3.8v-16ZM192.59 1.75h-1.42v3.87h-1V1.75h-1.37V1h3.79ZM194.52 1l1.19 3.38L196.9 1h1.26v4.62h-1V4.35l.1-2.2L196 5.62h-.66l-1.25-3.46.09 2.19v1.27h-1V1Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgTimevit)
export default ForwardRef
