import { css, useTheme } from "@emotion/react"
import * as Icon from "asset/icon-svg-react"
import { Link } from "gatsby"
import * as _ from "lodash"
import * as React from "react"
import HamburgerIcon from "src/components/atom/HamburgerIcon"
import Menu from "src/components/molecule/Menu"
import OffCanvasMenu from "src/components/molecule/OffCanvasMenu"
import ScrollUpDown from "src/help/ScrollUpDown"

const list = [
  {
    to:"eyesol",
    name:"آیسول"
  },
  {
    to:"vitalayer",
    name:"ویتالیر"
  },
  {
    to:"blephamed",
    name:"بلفامد"
  },
  {
    to:"elvina",
    name:"الوینا"
  },
  {
    to:"bergamia",
    name:"برگامیا"
  },
  {
    to:"vitaplex",
    name:"ویتاپلکس"
  },
  {
    to:"eudora",
    name:"ادورا"
  },
]
const list2 = [
  {
    to:"https://barijan.com/",
    name:"باریژان"
  },
  {
    to:"https://www.darukade.com/products?w=%D9%88%DB%8C%D8%AA%D8%A7%D9%84%DB%8C%D8%B1",
    name:"داروکده"
  },
  {
    to:"https://rojashop.com/search?term=%D9%88%DB%8C%D8%AA%D8%A7%D9%84%DB%8C%D8%B1",
    name:"روژا"
  },
  {
    to:"https://ruban.com/products/brand/ویتالیر-288892",
    name:"روبان"
  },
  {
    to:"https://www.khanoumi.com/Vitalayer",
    name:"خانمی"
  },

  {
    to:"https://mosbatesabz.com/brand/vitalayer/",
    name:"مثبت سبز"
  },
]
const Header = ({ canvasOpen, setCanvasOpen }) => {
  const theme = useTheme()
  const header = React.useRef()


  React.useEffect(() => {
      // add event listener
      const scrollFunction = _.throttle(
        ScrollUpDown(window, "scrollY", header.current,
          {
            initialClass: "initial",
            visibleClass: "visible",
            inVisibleClass: "invisible"
          }),
        100
      )
      document.addEventListener("scroll", scrollFunction)
      return () => {
        document.removeEventListener("scroll", scrollFunction)
      }
    }
  )
  const { secondary ,primary} = theme.colors
  const style = css`
    & ::selection {
        background-color: white;
        color: ${secondary};
      }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    min-height: 12vh;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 200;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid ${primary};
    border-top: 1px solid ${primary};
    background-color: ${secondary};
    transition: top 600ms ease;
    ${theme.breakpoint.md}{
      min-height: 8vh;
    }
    &.initial {
      top: 0;
    }

    &.visible {
      top: 0;
    }

    &.invisible {
      top: -101vh;
    }

    .logo {
      color: white !important;
      width: 150px;
      margin: 0 20px;
      ${theme.breakpoint.lg}{
        width: 100px;
      }
    }

    .offcanvas {
      display: none;
    }

    .hamburger {
      display: none;
      margin: 9px;
    }

    ${theme.breakpoint.xl} {
      justify-content: space-between;

      .offcanvas {
        display: block;
      }

      .hamburger {
        display: block;
      }

      .menu {
        display: none;
      }
    }
  `

  return (
    <>
      <nav dir="rtl" css={style} ref={header}>
        <Link to="/">
          <Icon.Logo2 className="logo" />
        </Link>
        <Menu className="menu" list={list} list2={list2} />
        <HamburgerIcon className="hamburger" canvasOpen={canvasOpen} setCanvasOpen={setCanvasOpen} />
        <OffCanvasMenu className="offcanvas" canvasOpen={canvasOpen} setCanvasOpen={setCanvasOpen} list={list} list2={list2} />
      </nav>
    </>
  )
}

export default Header
