import * as React from "react"
import { forwardRef } from "react"

const SvgLogoCircle = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 78 76.8"
    style={{
      enableBackground: "new 0 0 78 76.8",
    }}
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <circle
      cx={39.7}
      cy={38.6}
      r={38.2}
      style={{
        fill: "#7bdcd5",
      }}
    />
    <path
      d="M41 66.4v-6.8c1.8-.1 3.5-.7 4.9-1.9 2.2-2 2.4-5.5.3-7.7-.3-.3-.7-.6-1-.9-2.1-1.5-4.6-2.1-7.1-3-2.8-.9-5.6-1.9-8.3-3-3.6-1.6-6.3-4.2-6.8-8.2-.1-1.7-.1-3.4.1-5.1 1.4 4.2 7.8 7.1 12.8 8.6 4 1.2 8.1 2 11.8 3.9 1.6.8 3.1 1.9 4.5 3.1 4.1 3.9 4.1 11.4.3 16-2.7 3.2-6.6 5-10.8 5H41m-3-6.9c-1.9 0-3.8-.7-5.2-2-1.4-1.5-1.5-3.3-1.5-5.2h-7.5c-1 9.3 7.1 14.6 14.1 14l.1-6.8m-1-49c-.2 0-.4-.1-.7-.1-4.2 0-8.1 1.8-10.8 5-3.8 4.6-3.8 12.1.3 16 1.3 1.2 2.9 2.3 4.5 3.1 3.7 2 7.8 2.8 11.8 3.9 5 1.5 11.3 4.4 12.8 8.6.2-1.7.3-3.4.1-5.1-.5-4-3.2-6.6-6.8-8.2-2.7-1.1-5.5-2.1-8.3-3-2.4-.8-4.9-1.5-7.1-3-2.5-1.6-3.2-5-1.6-7.5.2-.4.5-.7.9-1 1.3-1.2 3-1.9 4.8-1.9v-6.8m3.1 6.8c1.9 0 3.8.7 5.1 2.1 1.4 1.5 1.5 3.3 1.5 5.2h7.5c1-9.3-7.1-14.6-14.1-14v6.7"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)

const ForwardRef = forwardRef(SvgLogoCircle)
export default ForwardRef
