import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"

const Root = styled.div`
  &.root {
    position: absolute;
    top: 100%;
     padding-right: ${props => props.marginRight}; 
    color: ${props => props.theme.colors.primary};
    transform: ${props => !props.open ? "translateY(-800%)" : "translateY(0)"};
    opacity: ${props => props.open ? "1" : "0"};
    background-color: #fff;
    transition: opacity 500ms ease;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    display: flex;
    justify-content: ${props => (props.type === "inside") ? "flex-start" : "center"};
    left: 0;
    right: 0;
    padding: 1rem;

  }

  &.root-vertical {
    display: flex;
    flex-direction: column;
    background-color: white;
    text-align: center;
    color: ${props => props.theme.colors.primary};
    height: ${props => props.open ? "auto" : "0"};
    transform: ${props => !props.open ? "translateX(-600%)" : "translateX(0)"};
    opacity: ${props => props.open ? "1" : "0"};
    transition: opacity 100ms ease;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    width: 100%;
    

  }

  .link {
    margin: 0.5rem;
    width: 100px;
  }
`
const BrandsMenu = ({ setCanvasOpen,open, vertical, list, type,marginRight }) => {
  const theme = useTheme()
  return (
    <Root className={vertical ? "root-vertical" : "root"} open={open} theme={theme} marginRight={marginRight} type={type}>
      {(type === "inside") && <div style={{ flexBasis: vertical ? 'auto' : marginRight - 30, flexGrow: 0 }} />}
      {(type === "outside") &&
        list.map((item) => {
            return (<a
              key={item.name}
              target="_blank"
              className="link" href={item.to}>
              {item.name}
              onClick={()=>setCanvasOpen(()=>false)}

            </a>)
          }
        )
      }
      {(type === "inside") &&
        list.map((item) => {
            return (<Link key={item.name} className="link" to={`/${item.to}`}
            onClick={()=>setCanvasOpen(()=>false)}
            >
              {item.name}
            </Link>)
          }
        )
      }
    </Root>
  )

}
export default BrandsMenu
