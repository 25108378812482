function ScrollUpDown(scrollRef,scrollProperty,element,{initialClass,visibleClass,inVisibleClass}) {
  let lastScroll = scrollRef[scrollProperty];
  let elementHeight = element.getBoundingClientRect().height;
  return function Scroll(event) {
    if (scrollRef[scrollProperty] <= elementHeight ) {
      element.classList.add(initialClass)
      element.classList.remove(visibleClass)
      element.classList.remove(inVisibleClass)
      return
    }
    if (scrollRef[scrollProperty] > lastScroll) {
      // means scroll down
      element.classList.add(inVisibleClass)
      element.classList.remove(visibleClass)
      element.classList.remove(initialClass)
    } else {
      // means scroll up
      element.classList.add(visibleClass)
      element.classList.remove(inVisibleClass)
      element.classList.remove(initialClass)
    }
    lastScroll = scrollRef[scrollProperty];
  }
}

export default ScrollUpDown

