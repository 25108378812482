import * as React from "react"
import { forwardRef } from "react"

const SvgTimevit2 = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 262.07 65.17"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".timevit-2_svg__cls-2{fill:#231f20}"}</style>
    </defs>
    <g id="timevit-2_svg__Layer_2" data-name="Layer 2">
      <g id="timevit-2_svg__Layer_1-2" data-name="Layer 1">
        <path
          d="M27.31 0h202.17a32.59 32.59 0 0 1 32.59 32.59 32.59 32.59 0 0 1-32.59 32.59H0V27.31A27.31 27.31 0 0 1 27.31 0Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          className="timevit-2_svg__cls-2"
          d="M27.36 18.01h11.97v37.16h6.36V18.01h12v-5.89H27.36v5.89zM62.28 11.39a3.69 3.69 0 0 0-1.13 2.75 3.64 3.64 0 0 0 1.13 2.73A3.72 3.72 0 0 0 65 18a3.77 3.77 0 0 0 2.74-1.11 3.85 3.85 0 0 0 0-5.46 3.92 3.92 0 0 0-5.46-.04ZM61.79 24h6.24v31.17h-6.24zM109 23.31a11.08 11.08 0 0 0-9.72 5.21 9.31 9.31 0 0 0-8.55-5.21 10.63 10.63 0 0 0-8.59 3.93V24h-6.27v31.17h6.24V37.5A10.14 10.14 0 0 1 84 31.13a5.5 5.5 0 0 1 4.57-2.34c4 0 6 2.62 6 8v18.38h6.24V37.5a10 10 0 0 1 2-6.21 5.58 5.58 0 0 1 4.58-2.5c2.16 0 3.64.62 4.51 1.89s1.39 3.59 1.39 6.71v17.78h6.24v-19c-.02-8.55-3.53-12.86-10.53-12.86ZM139.11 23.31a13.33 13.33 0 0 0-10.3 4.61 17 17 0 0 0-4.13 11.78c0 5.06 1.28 9 3.79 11.88s6 4.29 10.44 4.29 8.06-1 10.78-3l.31-.22v-6.36l-1.2 1a13.91 13.91 0 0 1-8.8 3.09 8.41 8.41 0 0 1-6.48-2.46 10 10 0 0 1-2.43-6.4h20.93v-3.24c0-4.62-1.14-8.3-3.37-10.94s-5.44-4.03-9.54-4.03Zm-.11 5.48a6.05 6.05 0 0 1 4.88 2 8.55 8.55 0 0 1 1.8 5.21h-14.39a10 10 0 0 1 2.55-5 7 7 0 0 1 5.16-2.21ZM168 46.18c-.12.32-.22.66-.32 1-.11-.36-.24-.73-.37-1.11L159.55 24h-6.82l11.81 31.17h5.68L182.64 24H176ZM185.61 24h6.24v31.17h-6.24zM186.09 11.39a3.72 3.72 0 0 0-1.09 2.75 3.64 3.64 0 0 0 1.13 2.73 3.72 3.72 0 0 0 2.66 1.13 3.76 3.76 0 0 0 2.73-1.11 3.64 3.64 0 0 0 1.14-2.7 3.7 3.7 0 0 0-1.14-2.76 3.93 3.93 0 0 0-5.43-.04ZM215 29.55V24h-7.48v-9.06l-6.24 2v7h-5.1v5.55h5.1v16.88c0 6.18 3 9.44 8.54 9.44a9.86 9.86 0 0 0 4.79-1l.39-.21v-6l-1.19.9a4.29 4.29 0 0 1-2.66.84 3.38 3.38 0 0 1-2.78-1 6.29 6.29 0 0 1-.85-3.8v-16ZM220 12h-1.43v3.88h-1V12h-1.4v-.78H220ZM221.88 11.26l1.19 3.38 1.19-3.38h1.26v4.66h-1v-1.27l.1-2.2-1.26 3.47h-.66l-1.25-3.47.09 2.2v1.27h-1v-4.66Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgTimevit2)
export default ForwardRef
