import * as React from "react"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import { Link } from "gatsby"
import ALink from "components/atom/ALink"
const list = [
  {
    to:"eyesol",
    name:"آیسول"
  },
  {
    to:"vitalayer",
    name:"ویتالیر"
  },
  {
    to:"blephamed",
    name:"بلفامد"
  },
  {
    to:"elvina",
    name:"الوینا"
  },
  {
    to:"bergamia",
    name:"برگامیا"
  },
  {
    to:"vitaplex",
    name:"ویتاپلکس"
  },
  {
    to:"eudora",
    name:"ادورا"
  },
]
const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  .list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin:1rem;
  }
  .link {
    flex-grow: 0;
    width: auto;
    cursor: pointer;
    color:white;
    transition: color 200ms ease;
    margin: 0 5px;
    &:hover {
      color:${props => props.theme.colors.accent};
    }
  }
  .header {
    font-weight: 900;
    font-size: 1.1em;
    margin-bottom: 10px;
    color:${props => props.theme.colors.accent};
  }
  ${props => props.theme.breakpoint.lg} {
    
  }
`

const FooterMenu = ({className}) => {
  const theme = useTheme()
  return (
    <>
      <Root theme={theme} className={className}>
        <div className="list">
          <div className="link header">برند ها</div>
          {
            list.map( item => <Link key={item.name} to={`/${item.to}`} className="link" >{item.name}</Link>)
          }
        </div>
        <div className="list">
          <div className="link header">خرید آنلاین</div>
          <a href="https://barijan.com/" className="link">باریژان</a>
          <a href="https://www.darukade.com/products?w=%D9%88%DB%8C%D8%AA%D8%A7%D9%84%DB%8C%D8%B1" className="link">داروکده</a>
          <a href="https://rojashop.com/search?term=%D9%88%DB%8C%D8%AA%D8%A7%D9%84%DB%8C%D8%B1" className="link">روژا</a>
          <a href="https://ruban.com/products/brand/ویتالیر-288892" className="link">روبان</a>
          <a href="https://www.khanoumi.com/Vitalayer" className="link">خانمی</a>
          <a href="" className="link">مثبت سبز</a>
        </div>
        <div className="list">
          <Link to="/contact-us" className="link header">همکاری با ما</Link>
          <Link to="/contact-us" className="link">ارسال رزومه</Link>
          <Link to="/contact-us" className="link">دریافت نمایندگی</Link>
        </div>
        <div className="list">
          <Link to="/contact-us" className="link header">تماس با ما</Link>
        </div>
        <div className="list">
          <Link  to="/branches" className="link header">نمایندگی ها</Link>
        </div>
      </Root>
    </>
  )
}

export default FooterMenu;