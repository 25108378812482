import * as React from "react"
import PropTypes from "prop-types"
import { GlobalStyle, theme } from "src/global-style/index.js"
import { ThemeProvider, Global } from "@emotion/react"



const WrapRootElement = ({ children ,theme3}) => {
  const theme2 = {...theme,...theme3}
  return (
    <ThemeProvider theme={theme2}>
      <Global styles={GlobalStyle} />
        {children}
    </ThemeProvider>
  )
}

WrapRootElement.propTypes = {
  children: PropTypes.node.isRequired
}

export default WrapRootElement