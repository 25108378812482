import * as React from "react"
import { forwardRef } from "react"

const SvgLogo = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32.39 56"
    style={{
      color: "#7bdcd5",
    }}
    ref={ref}
    {...props}
  >
    <g data-name="Layer 2">
      <path
        d="M18.15 55.93v-6.75A7.64 7.64 0 0 0 23 47.24a5.45 5.45 0 0 0-.71-8.58c-2.14-1.48-4.64-2.13-7.06-3a85.22 85.22 0 0 1-8.34-3C3.34 31.07.62 28.5.08 24.46a21.7 21.7 0 0 1 .15-5.08C1.67 23.58 8 26.5 13 28c4 1.17 8.1 2 11.8 3.92A20.25 20.25 0 0 1 29.29 35c4.08 3.87 4.07 11.42.31 15.95a14.08 14.08 0 0 1-10.79 5 4.25 4.25 0 0 1-.66-.07m-3-6.78A7.49 7.49 0 0 1 10 47.09c-1.43-1.47-1.49-3.33-1.46-5.21H1.09c-1 9.3 7.13 14.59 14.08 14v-6.73M14.23.07a4 4 0 0 0-.66-.07A14.06 14.06 0 0 0 2.79 5C-1 9.56-1 17.11 3.1 21a20 20 0 0 0 4.48 3.13c3.7 2 7.82 2.75 11.8 3.92 5 1.47 11.33 4.39 12.78 8.59a22.2 22.2 0 0 0 .14-5.08c-.54-4-3.25-6.61-6.79-8.23a85 85 0 0 0-8.33-3c-2.42-.84-4.92-1.49-7.07-3a5.45 5.45 0 0 1-.7-8.58 7.64 7.64 0 0 1 4.82-1.94V.07m3 6.78a7.51 7.51 0 0 1 5.14 2.06c1.43 1.47 1.49 3.33 1.45 5.21h7.48c1-9.3-7.13-14.59-14.08-14v6.73"
        style={{
          fill: "currentColor",
        }}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgLogo)
export default ForwardRef
