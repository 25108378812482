import { useState, useRef } from "react";

function OnHoverClick(hoverChangedelay=0,option={}) {
  //options (hover: true or false) , (click: true or false)
  if (option.hover == undefined) {
    option.hover = true;
  }
  if (option.click == undefined) {
    option.click = true;
  }

  const [activeState, setActiveState] = useState(false);
  const timeoutId = useRef(null);


  const handleHover = (futureState,event) => {
    clearTimeout(timeoutId.current);

    if(event.type == 'click') {
      setActiveState(futureState);
    }

    // #### if mouseEnter or mouseLeave (hover)
    // activeState (2 condition), state (2 condition) 2x2=4 condition
    // if "activeState is true" and "futureState is true" so no need to change
    // if "activeState is false" and "futureState is true" so :
    if (activeState == false && futureState == true) {
      setActiveState(true);
    }
    // if "activeState is false" and "futureState is false" so no need to change
    // if "activeState is true" and "futureState is false" so :
    if (activeState == true && futureState == false) {
      timeoutId.current = setTimeout(() => {
        setActiveState(false);
      }, hoverChangedelay);
    }
  };
  const composedEvents = {
    onMouseEnter: (option.hover ? (event) => handleHover(true,event)  : null),
    onMouseLeave: (option.hover ? (event) => handleHover(false,event) : null),
    onClick     : (option.click ? (event) => handleHover(!activeState,event) : null)
  };
  return [activeState, composedEvents];
}

export default OnHoverClick;