import * as React from "react"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import Socials from "src/components/molecule/socials"
import * as Icon from "src/asset/icon-svg-react"
import { useStaticQuery, graphql } from "gatsby"
import FooterMenu from "src/components/atom/FooterMenu"

const Root = styled.div`
  background-color: ${props => props.theme.colors.secondary};
  padding: 1rem 0 0 0;

  & * {
    ::selection {
      background-color: white;
      color: ${props => props.theme.colors.secondary};
    }
  }

  .wrapper {
    margin: auto;
    max-width: 1000px;
    width: 100%;
  }

  .header-footer {
    display: flex;

    ${props => props.theme.breakpoint.lg} {
      flex-direction: column;
    }

    background-color: ${props => props.theme.colors.secondary};

    .logo {
      color: white;
      height: 80px;
      flex-grow: 1.2;
      margin: 1rem 0;
      ${props => props.theme.breakpoint.lg} {
        height: 50px;
        margin: 1rem 0;
      }
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: ${props => props.theme.colors.secondary};

    hr {
      background-color: white;
      outline: none;
      height: 3px;
      width: 100%;
      margin: 1rem auto 0 auto;

    }
  }

  .wrapper2 {
    margin: 1rem 0 0 0;
    background-color: grey;

    .footer {
      height: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: auto;
      justify-content: center;
      background-image: url("/body-background.jpg");
      ${props => props.theme.breakpoint.sm} {
        height: 110px;
      }

      .icon {
        color: white;
        max-width: 100px;
        max-height: 100px;
        filter: grayscale(1);
        transition: filter 200ms ease;
        flex-grow: 0;
        margin: 1em;

        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
`

const Footer = (props) => {
  const theme = useTheme()
  const addressData = useStaticQuery(graphql`
      query SiteAddress {
          site {
              siteMetadata {
                  address {
                      fa_IR
                  }
                  tel {
                      fa_IR
                  }
              }
          }
      }
  `)

  return (
    <>
      <Root theme={theme} dir="rtl">
        <div className="wrapper">
          <div className="header-footer">
            <Icon.Logo2 className="logo" />
            <FooterMenu />
          </div>
          <address className="address">
            <hr />
            <p className="margin-top">
              {addressData.site.siteMetadata.address.fa_IR}
              <a href={`tel:${addressData.site.siteMetadata.tel.fa_IR}`} />
            </p>
            <Socials className="socials" reverseTheme={true} />
          </address>
        </div>
        <div className="wrapper2">

          <div className="footer">
            <a href="https://axon-pack.com/" target="_blank">
              <Icon.Axon className="icon" />
            </a>
            <a href="http://marantogroup.com/" target="_blank">
              <Icon.Deric className="icon" />
            </a>
            <a href="http://marantogroup.com" target="_blank">
              <Icon.SadraFood className="icon" />
            </a>
            <a href="https://psfcosmetics.com" target="_blank">
              <Icon.PsfLogo className="icon" />
            </a>
          </div>
        </div>
      </Root>
    </>
  )
}

export default Footer
