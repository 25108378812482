// NavItem.js
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import ReactChildrenProp from "help/ReactChildrenProp"
import OnHoverClick from "hook/onHoverClick"
import * as React from "react"
import Alink from "src/components/atom/ALink"

const NoLink = styled.div`
  display: ${props => props.vertical ? "flex" : "block"};
  cursor: pointer;
  flex-direction: column;
  padding: 0.8rem;
  margin: 0.5rem;
  transition: all 100ms ease;
  border-bottom: 4px solid transparent;
  border-bottom: ${props => props.open ? `4px solid ${props.theme.colors.accent}` : `4px solid transparent`};
  color: ${props => props.open ? `${props.theme.colors.accent}` : `${props.theme.colors.text}`};

  &:hover {
    border-bottom: ${props => `4px solid ${props.theme.colors.accent}`};
    color: ${props => props.theme.colors.accent};
  }
`

const OutsideLink = styled(Alink)`
  display: block;
  padding: 0.8rem;
  margin: 0.5rem;
  transition: all 100ms ease;
  border-bottom: 4px solid transparent;
  border-bottom: ${props => props.open ? `4px solid ${props.theme.colors.accent}` : `4px solid transparent`};
  color: ${props => props.open ? `${props.theme.colors.accent}` : `${props.theme.colors.text}`};
`

const InsideLink = styled(Alink)`
  display: block;
  padding: 0.8rem;
  margin: 0.5rem;
  transition: all 100ms ease;
  border-bottom: 4px solid ${props => props.open ? props.theme.colors.accent : "transparent"};
  color: ${props => props.open ? `${props.theme.colors.accent}` : `${props.theme.colors.text}`};
`

const NavItem = ({ setCanvasOpen,children, href, text, type, vertical, setMarginRight }) => {
  const theme = useTheme()
  const brands = React.useRef()

  React.useEffect(() => {
    if (text === "برند ها") {
      setMarginRight(document.body.getBoundingClientRect().width - brands.current.getBoundingClientRect().right)
    }
  })
  const [open, composedEvent] = vertical ? OnHoverClick(0, { hover: false }) : OnHoverClick(100)
  const timeOutID = React.useRef()

  const childrenWithProps = ReactChildrenProp(children, { open: open })

  switch (type) {
    case "nolink":
      return (
        <NoLink
          ref={brands}
          theme={theme}
          open={open}
          {...composedEvent}
          vertical={vertical}
        >
          <li>{text}</li>
          {childrenWithProps}
        </NoLink>
      )
    case "inside":
      return (
        <InsideLink
          type="inside"
          theme={theme}
          open={open}
          to={href}
          {...composedEvent}
          onClick={()=>{setCanvasOpen(()=>false)}}
        >
          <li>{text}</li>
        </InsideLink>
      )
    case "outside" :
      return (
        <OutsideLink
          type="outside"
          theme={theme}
          open={open}
          href={href}
          {...composedEvent}
        >
          <li>{text}</li>
        </OutsideLink>
      )
  }
}


export default NavItem