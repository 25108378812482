import * as React from "react"
import { forwardRef } from "react"

const SvgWhitevit = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 210.83 44.67"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".whitevit_svg__cls-1{fill:#929497}"}</style>
    </defs>
    <g id="whitevit_svg__Layer_2" data-name="Layer 2">
      <g id="whitevit_svg__Layer_1-2" data-name="Layer 1">
        <path
          className="whitevit_svg__cls-1"
          d="m37.66 34.26-.09.33-.06-.23L29.17 4h-5.39l-8.69 30.14-.15.53c0-.18-.09-.35-.14-.53L6.44 4H0l11.73 40H18l8.18-28.8a.83.83 0 0 0 0-.15l.06.21L34.38 44h6.36L52.05 4H45.7ZM69.61 14.38a10.49 10.49 0 0 0-8.48 3.91V1.76h-5.81V44h5.81V27.69a8.69 8.69 0 0 1 2-6 6.3 6.3 0 0 1 4.94-2.26c3.85 0 5.72 2.6 5.72 7.94V44h5.81V26.32c.03-7.92-3.34-11.94-9.99-11.94ZM86.75 3.29a3.46 3.46 0 0 0-1 2.56 3.39 3.39 0 0 0 1.05 2.54 3.46 3.46 0 0 0 2.5 1 3.52 3.52 0 0 0 2.55-1 3.4 3.4 0 0 0 1-2.51 3.4 3.4 0 0 0-1.05-2.59 3.65 3.65 0 0 0-5.05 0ZM86.3 15.03h5.8v29h-5.8zM106.64 6.6l-5.81 1.87V15h-4.74v5.15h4.74v15.69c0 5.74 2.75 8.78 8 8.78a9.22 9.22 0 0 0 4.46-.95l.35-.2V37.9l-1.1.84a4 4 0 0 1-2.47.77 3.17 3.17 0 0 1-2.59-.92 5.86 5.86 0 0 1-.79-3.54V20.18h7V15h-7ZM128.94 14.38a12.4 12.4 0 0 0-9.58 4.29 15.78 15.78 0 0 0-3.83 11c0 4.7 1.18 8.42 3.51 11.05a12.4 12.4 0 0 0 9.72 4c4.12 0 7.49-.93 10-2.77l.28-.21v-5.98l-1.11.88a13 13 0 0 1-8.15 2.93 7.84 7.84 0 0 1-6-2.29 9.32 9.32 0 0 1-2.26-6H141v-3c0-4.3-1.05-7.73-3.13-10.18s-5.12-3.72-8.93-3.72Zm-.08 5.1a5.61 5.61 0 0 1 4.54 1.89 8 8 0 0 1 1.66 4.82h-13.39a9.3 9.3 0 0 1 2.38-4.68 6.42 6.42 0 0 1 4.81-2.03ZM155.77 35.66q-.17.45-.3.93c-.1-.34-.21-.68-.34-1L148 15h-6.35l11 29h5.29l11.55-29h-6.17ZM172.65 3.29a3.46 3.46 0 0 0-1 2.56 3.42 3.42 0 0 0 1 2.54 3.48 3.48 0 0 0 2.51 1 3.52 3.52 0 0 0 2.55-1 3.4 3.4 0 0 0 1-2.51 3.44 3.44 0 0 0-1.06-2.56 3.65 3.65 0 0 0-5-.03ZM172.2 15.03h5.8v29h-5.8zM199.49 20.18V15h-7V6.6l-5.81 1.87V15H182v5.15h4.74v15.69c0 5.74 2.75 8.78 8 8.78a9.27 9.27 0 0 0 4.46-.95l.35-.2V37.9l-1.1.84a4 4 0 0 1-2.47.77 3.17 3.17 0 0 1-2.59-.92 5.86 5.86 0 0 1-.79-3.54V20.18ZM205.26.78h-1.43v3.88h-1V.78h-1.41V0h3.8ZM207.18 0l1.2 3.38L209.57 0h1.26v4.66h-1V3.38l.1-2.19-1.23 3.47h-.7l-1.25-3.47.1 2.19v1.28h-1V0Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgWhitevit)
export default ForwardRef
