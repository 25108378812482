import * as React from "react"
import { forwardRef } from "react"

const SvgActivit = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 176.13 45.52"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".activit_svg__cls-1{fill:#93d500}"}</style>
    </defs>
    <g id="activit_svg__Layer_2" data-name="Layer 2">
      <g id="activit_svg__Layer_1-2" data-name="Layer 1">
        <path
          className="activit_svg__cls-1"
          d="M16.49 1.82 0 44.82h7l4.14-11.64h16.58l4.4 11.64h7l-16.55-43Zm2.92 8.82 6.15 16.7H13.31ZM56.57 13c-4.86 0-8.81 1.57-11.74 4.67s-4.4 7.23-4.4 12.33a15.73 15.73 0 0 0 4.1 11.21 14.19 14.19 0 0 0 10.77 4.31 16.17 16.17 0 0 0 8.49-2.17l.36-.21v-6.43l-1.19.9A11.22 11.22 0 0 1 56.11 40a8.65 8.65 0 0 1-6.79-2.77 11.11 11.11 0 0 1-2.54-7.72 11.71 11.71 0 0 1 2.72-8.07 8.78 8.78 0 0 1 6.9-3 11.45 11.45 0 0 1 6.6 2.21l1.17.82v-6.75l-.42-.2A16.41 16.41 0 0 0 56.57 13ZM77.32 4.64l-6.24 2v7H66v5.54h5.09V36c0 6.17 3 9.43 8.53 9.43a10 10 0 0 0 4.79-1l.39-.21v-6l-1.19.9A4.25 4.25 0 0 1 81 40a3.37 3.37 0 0 1-2.78-1 6.29 6.29 0 0 1-.85-3.8v-16h7.47v-5.51h-7.52ZM89.35 13.69h6.23v31.13h-6.23zM89.83 1.09a3.73 3.73 0 0 0-1.12 2.74 3.68 3.68 0 0 0 1.12 2.74 3.9 3.9 0 0 0 5.42 0 3.66 3.66 0 0 0 1.14-2.71 3.69 3.69 0 0 0-1.14-2.74 3.92 3.92 0 0 0-5.42-.03ZM113.88 35.84c-.12.32-.22.65-.32 1-.11-.36-.24-.73-.37-1.11l-7.7-22h-6.81l11.79 31.13h5.68l12.4-31.13h-6.62ZM132 1.09a3.73 3.73 0 0 0-1.12 2.74A3.68 3.68 0 0 0 132 6.57a3.91 3.91 0 0 0 5.43 0 3.69 3.69 0 0 0 1.13-2.71 3.65 3.65 0 0 0-1.14-2.74 3.92 3.92 0 0 0-5.42-.03ZM131.52 13.69h6.23v31.13h-6.23zM160.82 19.23v-5.54h-7.47v-9l-6.23 2v7H142v5.54h5.1V36c0 6.17 2.95 9.43 8.53 9.43a9.94 9.94 0 0 0 4.78-1l.39-.21v-6l-1.19.9A4.23 4.23 0 0 1 157 40a3.37 3.37 0 0 1-2.78-1 6.29 6.29 0 0 1-.85-3.8v-16ZM167.71 1.79h-2.16v5.86h-1.45V1.79H162V.61h5.73ZM170.62.61l1.8 5.1 1.8-5.1h1.91v7h-1.46V5.72l.15-3.32-1.9 5.25h-1L170 2.41l.14 3.31v1.93h-1.45v-7Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgActivit)
export default ForwardRef
