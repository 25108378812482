import * as React from "react"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import Menu from "src/components/molecule/Menu.js"

const Root = styled.div`
  overflow: auto;
  position: absolute;
  left: 0;
  top: 0;
  padding-top:15vh;
  height: 100vh;
  width: 300px;
  transform: ${props => props.canvasOpen ? "translateX(0)" : "translateX(-500px)"};
  background-color: ${props => props.theme.colors.secondary};
  transition: transform 200ms ease;
  ${props => props.theme.breakpoint.md} {
    width: 100vw;
    transform: ${props => props.canvasOpen ? "translateX(0)" : "translateX(-100vw)"};
  }
`

const OffCanvasMenu = ({ setCanvasOpen,canvasOpen, className,list,list2 }) => {
  const theme = useTheme()
  return (
    <>
      <Root theme={theme} className={className} canvasOpen={canvasOpen} >
        <Menu vertical  list={list} list2={list2} setCanvasOpen={setCanvasOpen}/>
      </Root>
    </>
  )
}

export default OffCanvasMenu