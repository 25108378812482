import * as React from "react"
import { forwardRef } from "react"

const SvgPointerRight = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    style={{
      fill: "currentColor",
    }}
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <path d="M24 1c12.7 0 23 10.3 23 23S36.7 47 24 47 1 36.7 1 24 11.3 1 24 1m0-1C10.7 0 0 10.7 0 24s10.7 24 24 24 24-10.7 24-24S37.3 0 24 0z" />
    <path d="M25.2 24 16 33.2l2.8 2.8 12-12-12-12-2.8 2.8 9.2 9.2z" />
  </svg>
)

const ForwardRef = forwardRef(SvgPointerRight)
export default ForwardRef
