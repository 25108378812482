import * as React from "react"
import { forwardRef } from "react"

const SvgHydravit2 = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 262.07 65.17"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".hydravit-2_svg__cls-2{fill:#3c7bb6}"}</style>
    </defs>
    <g id="hydravit-2_svg__Layer_2" data-name="Layer 2">
      <g id="hydravit-2_svg__Layer_1-2" data-name="Layer 1">
        <path
          d="M27.31 0h202.17a32.59 32.59 0 0 1 32.59 32.59 32.59 32.59 0 0 1-32.59 32.59H0V27.31A27.31 27.31 0 0 1 27.31 0Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          className="hydravit-2_svg__cls-2"
          d="M51.09 27.07H32.01V9.7h-6.05v41.01h6.05V32.65h19.08v18.06h6.05V9.7h-6.05v17.37zM69.86 55A6.83 6.83 0 0 1 68 57.74h6.67a24.4 24.4 0 0 0 1.41-2.94L89.48 21h-6.23l-8.18 22.23L67.26 21H60.7L72 49.94ZM111.64 23.6a9.94 9.94 0 0 0-7.88-3.24 12.39 12.39 0 0 0-10 4.47c-2.45 2.94-3.7 6.87-3.7 11.7 0 4.51 1.13 8.15 3.36 10.8a12.22 12.22 0 0 0 18.18 0v3.39h6V7.43h-6Zm-2.07 4.14a7.3 7.3 0 0 1 2.07 5.31v4.17a9.5 9.5 0 0 1-2.19 6.44 7 7 0 0 1-5.61 2.49 6.71 6.71 0 0 1-5.56-2.57 11.32 11.32 0 0 1-2.12-7.27c0-3.4.74-6.07 2.22-8a7.11 7.11 0 0 1 6-2.77 6.85 6.85 0 0 1 5.19 2.2ZM137.81 20.52a7.43 7.43 0 0 0-4.9 1.84 9.31 9.31 0 0 0-1.91 2.21V21h-5.94v29.71H131V35.59a12 12 0 0 1 1.82-7.05 5 5 0 0 1 4.27-2.43 4.88 4.88 0 0 1 3 .76l1.14.87v-6.6l-.44-.18a7.88 7.88 0 0 0-2.98-.44ZM155.42 20.36a18.22 18.22 0 0 0-10 2.76l-.33.21v6.55l1.17-1a13.29 13.29 0 0 1 8.74-3.3c2.12 0 4.91.63 5.11 5.89l-7.86 1.1c-6.59.92-9.94 4.26-9.94 9.92A8.5 8.5 0 0 0 145 49a11.1 11.1 0 0 0 15.2-1.26v3h5.95V31.61c-.05-7.47-3.65-11.25-10.73-11.25Zm4.73 16.15v2a7.67 7.67 0 0 1-2 5.49 6.54 6.54 0 0 1-5.05 2.14 5 5 0 0 1-3.47-1.14 3.63 3.63 0 0 1-1.24-2.86c0-1.66.43-2.77 1.3-3.38a10.49 10.49 0 0 1 4.44-1.44ZM182.92 42.15c-.11.3-.21.62-.31 1-.1-.35-.22-.7-.35-1.06l-7.34-21h-6.5l11.25 29.69h5.41L196.92 21h-6.32ZM200.2 9a3.57 3.57 0 0 0-1.06 2.62 3.47 3.47 0 0 0 1.07 2.6 3.72 3.72 0 0 0 5.17 0 3.47 3.47 0 0 0 1.08-2.57A3.48 3.48 0 0 0 205.38 9a3.75 3.75 0 0 0-5.18 0ZM199.74 21.02h5.94v29.69h-5.94zM227.69 26.3V21h-7.12v-8.61l-5.94 1.92V21h-4.87v5.3h4.87v16c0 5.88 2.81 9 8.13 9a9.53 9.53 0 0 0 4.57-1l.36-.2v-5.66l-1.13.86a4.09 4.09 0 0 1-2.53.8 3.22 3.22 0 0 1-2.65-1 6 6 0 0 1-.81-3.62V26.3ZM232.29 10.05h-1.42v3.88h-1v-3.88h-1.37v-.77h3.79ZM234.22 9.28l1.2 3.37 1.18-3.37h1.27v4.65h-1v-1.27l.1-2.2-1.26 3.47h-.66l-1.25-3.46.09 2.19v1.27H233V9.28Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgHydravit2)
export default ForwardRef
