import * as React from "react"
import { css } from "@emotion/react"
import BrandsMenu from "src/components/molecule/BrandsMenu"
import NavItem from "src/components/atom/NavItem"

// Menu
const Menu = ({setCanvasOpen,vertical,className,list,list2}) => {
  const style = css`
    text-align: center;

    flex-direction: ${vertical ? "column" : "row"};
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    overflow: hidden;
   
    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
  const [marginRight,setMarginRight] = React.useState("auto")
  return (
    <div css={style} className={className}>
      <NavItem type="nolink" href="/" text="برند ها" vertical={vertical} setMarginRight={setMarginRight} >
        <BrandsMenu setCanvasOpen={setCanvasOpen} type="inside" vertical={vertical} list={list} marginRight={marginRight} />
      </NavItem>
      <NavItem setCanvasOpen={setCanvasOpen} type="inside" href="/" text="دسته بندی محصولات" />
      <NavItem type="nolink"  text="خرید آنلاین" vertical={vertical}>
        <BrandsMenu setCanvasOpen={setCanvasOpen} type="outside" vertical={vertical} list={list2}/>
      </NavItem>
      <NavItem setCanvasOpen={setCanvasOpen} type="inside" href="/branches" text="نمایندگی ها" />
      <NavItem setCanvasOpen={setCanvasOpen} type="inside" href="/contact-us" text="همکاری با ما" />
      <NavItem setCanvasOpen={setCanvasOpen} type="inside" href="/contact-us" text="تماس با ما"/>
    </div>
  )
}

export default Menu