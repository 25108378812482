import * as React from "react"
import * as Icon from "src/asset/icon-svg-react"
import styled from "@emotion/styled"
import {  useTheme } from "@emotion/react"

const Root = styled.div`
  display: flex;
  width: 20vw;
  ${props => props.theme.breakpoint.md} {
    width: 40vw;
  }
  justify-content:space-between ;
  a {
    padding: .5rem;
    display: flex;
  }

  
  .icon {
    width: 2em;
    color: ${props => props.reverseTheme ? `white` : `${props.theme.colors.primary}`};
    transition: color 100ms ease, transform 100ms ease;
    &:hover {
      color: ${props => props.reverseTheme ? `${props.theme.colors.primary}` : "white"};
      transform: scale(1.1);
    }
  }
`


const Socials = ({ className , reverseTheme=false }) => {
  const theme = useTheme()
  return (
    <Root theme={theme} className={className} reverseTheme={reverseTheme}>
      <a href="https://www.aparat.com/Sayatebmana" target="_blank">
        <Icon.Aparat className="icon" width="100%" />
      </a>
      <a href="https://www.instagram.com/sayateb.mana/" target="_blank">
        <Icon.Instagram className="icon" width="100%" />
      </a>
      <a href="" target="_blank">
        <Icon.Linkedin className="icon" width="100%" />
      </a>
      <a href="https://www.youtube.com/channel/UC2Ab7csnoIAT6jFeJgSW9Bg" target="_blank">
        <Icon.Youtube className="icon" width="100%" />
      </a>
    </Root>
  )
}

export default Socials
