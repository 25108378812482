import * as React from "react"
import { forwardRef } from "react"

const SvgWhitevit2 = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 262.07 65.17"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".whitevit-2_svg__cls-2{fill:#929497}"}</style>
    </defs>
    <g id="whitevit-2_svg__Layer_2" data-name="Layer 2">
      <g id="whitevit-2_svg__Layer_1-2" data-name="Layer 1">
        <path
          d="M27.31 0h202.17a32.59 32.59 0 0 1 32.59 32.59 32.59 32.59 0 0 1-32.59 32.59H0V27.31A27.31 27.31 0 0 1 27.31 0Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          className="whitevit-2_svg__cls-2"
          d="m63.28 45.57-.09.33-.06-.23-8.34-30.39h-5.38l-8.7 30.17-.15.53c0-.17-.09-.35-.14-.53l-8.36-30.17h-6.44l11.73 40.05h6.3l8.15-28.82c0-.05 0-.1.05-.15a1.6 1.6 0 0 0 0 .21L60 55.33h6.36l11.31-40h-6.35ZM95.23 25.69a10.49 10.49 0 0 0-8.48 3.91V13.07h-5.81v42.26h5.81V39a8.65 8.65 0 0 1 2-6 6.27 6.27 0 0 1 4.93-2.26c3.85 0 5.72 2.6 5.72 7.94v16.6h5.81V37.63c.04-7.92-3.33-11.94-9.98-11.94ZM112.37 14.6a3.46 3.46 0 0 0-1 2.56 3.39 3.39 0 0 0 1 2.54 3.47 3.47 0 0 0 2.5 1 3.52 3.52 0 0 0 2.55-1 3.57 3.57 0 0 0 0-5.07 3.65 3.65 0 0 0-5.05-.03ZM111.93 26.34h5.8v29h-5.8zM132.26 17.91l-5.81 1.87v6.56h-4.74v5.15h4.74v15.66c0 5.74 2.75 8.78 8 8.78a9.22 9.22 0 0 0 4.46-1l.36-.2v-5.52l-1.11.84a4 4 0 0 1-2.47.77 3.17 3.17 0 0 1-2.59-.92 5.86 5.86 0 0 1-.79-3.54V31.49h7v-5.15h-7ZM154.57 25.69A12.41 12.41 0 0 0 145 30a15.83 15.83 0 0 0-3.83 11c0 4.71 1.18 8.42 3.51 11.05a12.4 12.4 0 0 0 9.72 4c4.12 0 7.49-.93 10-2.77l.29-.21v-6l-1.12.88a13 13 0 0 1-8.15 2.93 7.84 7.84 0 0 1-6-2.29 9.32 9.32 0 0 1-2.26-6h19.47v-3c0-4.3-1.05-7.73-3.13-10.18s-5.12-3.72-8.93-3.72Zm-.08 5.1a5.6 5.6 0 0 1 4.51 1.89 8 8 0 0 1 1.66 4.82H147.3a9.21 9.21 0 0 1 2.37-4.68 6.44 6.44 0 0 1 4.82-2.03ZM181.39 47c-.11.3-.2.61-.3.93-.1-.34-.21-.68-.34-1l-7.17-20.52h-6.35l11 29h5.29l11.55-29h-6.17ZM198.27 14.6a3.46 3.46 0 0 0-1 2.56 3.39 3.39 0 0 0 1.05 2.54 3.46 3.46 0 0 0 2.5 1 3.52 3.52 0 0 0 2.55-1 3.4 3.4 0 0 0 1-2.51 3.44 3.44 0 0 0-1.06-2.56 3.65 3.65 0 0 0-5.04-.03ZM197.82 26.34h5.8v29h-5.8zM225.11 31.49v-5.15h-7v-8.43l-5.81 1.87v6.56h-4.74v5.15h4.74v15.66c0 5.74 2.75 8.78 8 8.78a9.22 9.22 0 0 0 4.46-1l.35-.2v-5.52l-1.1.84a4 4 0 0 1-2.47.77 3.17 3.17 0 0 1-2.54-.92 5.86 5.86 0 0 1-.79-3.54V31.49ZM230.88 12.09h-1.43V16h-1v-3.91h-1.41v-.78h3.8ZM232.8 11.31l1.2 3.38 1.19-3.38h1.26V16h-1v-1.31l.09-2.19-1.22 3.5h-.65l-1.26-3.47.1 2.19V16h-1v-4.69Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgWhitevit2)
export default ForwardRef
