exports.components = {
  "component---src-components-template-brand-js": () => import("./../../../src/components/template/Brand.js" /* webpackChunkName: "component---src-components-template-brand-js" */),
  "component---src-components-template-coming-soon-js": () => import("./../../../src/components/template/ComingSoon.js" /* webpackChunkName: "component---src-components-template-coming-soon-js" */),
  "component---src-components-template-pdf-book-js": () => import("./../../../src/components/template/PDFBook.js" /* webpackChunkName: "component---src-components-template-pdf-book-js" */),
  "component---src-components-template-vitalayer-js": () => import("./../../../src/components/template/Vitalayer.js" /* webpackChunkName: "component---src-components-template-vitalayer-js" */),
  "component---src-components-template-vitalayer-sub-brand-js": () => import("./../../../src/components/template/VitalayerSubBrand.js" /* webpackChunkName: "component---src-components-template-vitalayer-sub-brand-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-branches-js": () => import("./../../../src/pages/branches.js" /* webpackChunkName: "component---src-pages-branches-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

