import * as React from "react"
import { forwardRef } from "react"

const SvgActivit2 = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 262.07 65.17"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".activit-2_svg__cls-2{fill:#93d500}"}</style>
    </defs>
    <g id="activit-2_svg__Layer_2" data-name="Layer 2">
      <g id="activit-2_svg__Layer_1-2" data-name="Layer 1">
        <path
          d="M27.31 0h202.17a32.59 32.59 0 0 1 32.59 32.59 32.59 32.59 0 0 1-32.59 32.59H0V27.31A27.31 27.31 0 0 1 27.31 0Z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          className="activit-2_svg__cls-2"
          d="M59.46 11.65 43 54.65h7L54.13 43H70.7l4.4 11.64h7l-16.55-43Zm2.92 8.82 6.14 16.7H56.29ZM99.55 22.82c-4.86 0-8.82 1.58-11.75 4.68s-4.4 7.22-4.4 12.28A15.73 15.73 0 0 0 87.5 51a14.18 14.18 0 0 0 10.77 4.35 16.16 16.16 0 0 0 8.49-2.16l.36-.21v-6.44l-1.19.9a11.22 11.22 0 0 1-6.85 2.43 8.66 8.66 0 0 1-6.79-2.77 11.11 11.11 0 0 1-2.54-7.73 11.69 11.69 0 0 1 2.72-8.06 8.78 8.78 0 0 1 6.9-3 11.45 11.45 0 0 1 6.63 2.17l1.17.82v-6.75l-.42-.2a16.4 16.4 0 0 0-7.2-1.53ZM120.29 14.47l-6.23 2v7H109V29h5.1v16.86c0 6.17 3 9.43 8.53 9.43a9.94 9.94 0 0 0 4.78-1l.39-.21v-6l-1.19.89a4.18 4.18 0 0 1-2.65.84 3.38 3.38 0 0 1-2.78-1 6.26 6.26 0 0 1-.85-3.79v-16h7.47v-5.5h-7.47ZM132.32 23.52h6.23v31.13h-6.23zM132.8 10.92a3.71 3.71 0 0 0-1.12 2.74 3.69 3.69 0 0 0 1.12 2.74 3.93 3.93 0 0 0 5.43 0 3.67 3.67 0 0 0 1.13-2.7 3.65 3.65 0 0 0-1.14-2.74 3.91 3.91 0 0 0-5.42-.04ZM156.85 45.67c-.12.32-.22.65-.32 1-.11-.36-.23-.73-.37-1.11l-7.7-22h-6.81l11.8 31.13h5.67l12.4-31.13h-6.62ZM175 10.92a3.71 3.71 0 0 0-1.12 2.74A3.67 3.67 0 0 0 175 16.4a3.91 3.91 0 0 0 5.42 0 3.67 3.67 0 0 0 1.13-2.7 3.65 3.65 0 0 0-1.14-2.74 3.91 3.91 0 0 0-5.41-.04ZM174.49 23.52h6.23v31.13h-6.23zM203.79 29.05v-5.53h-7.47v-9l-6.23 2v7H185v5.53h5.1v16.81c0 6.17 2.95 9.43 8.53 9.43a10 10 0 0 0 4.79-1l.38-.21v-6l-1.2.92a4.18 4.18 0 0 1-2.65.84 3.39 3.39 0 0 1-2.78-1 6.33 6.33 0 0 1-.85-3.79v-16ZM210.68 11.61h-2.15v5.86h-1.45v-5.86H205v-1.17h5.73ZM213.59 10.44l1.81 5.1 1.79-5.1h1.91v7h-1.46v-1.89l.15-3.32-1.9 5.24h-1L213 12.24l.14 3.31v1.92h-1.44v-7Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgActivit2)
export default ForwardRef
