import * as React from "react"
import { forwardRef } from "react"

const SvgAparat = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.35 36"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".aparat_svg__cls-1{fill:currentColor}"}</style>
    </defs>
    <g id="aparat_svg__Layer_2" data-name="Layer 2">
      <g id="aparat_svg__Layer_1-2" data-name="Layer 1">
        <path
          className="aparat_svg__cls-1"
          d="M33.93 18a15.62 15.62 0 0 1-10.56 14.73A15.79 15.79 0 0 1 3 22.31a15.29 15.29 0 0 1-.55-5.59A15.71 15.71 0 0 1 19.2 2.43a15.58 15.58 0 0 1 10.55 5 15 15 0 0 1 3.94 7.92c.14.88.2 1.76.29 2.65Zm-27 4.83a4.49 4.49 0 1 0 4.46-4.45 4.47 4.47 0 0 0-4.5 4.44ZM25 17.63a4.46 4.46 0 1 0-4.48-4.55A4.47 4.47 0 0 0 25 17.63Zm-2.34 11.79A4.45 4.45 0 1 0 18.17 25a4.47 4.47 0 0 0 4.49 4.42Zm-9-22.84a4.45 4.45 0 1 0 0 8.9 4.45 4.45 0 1 0 0-8.9Zm4.43 13.5a2 2 0 0 0 2-2 2 2 0 1 0-2 2Z"
        />
        <path
          className="aparat_svg__cls-1"
          d="M35.19 15.81a17.46 17.46 0 0 0-6.56-11.34l2.37.64a13.92 13.92 0 0 1 2 .63A5.42 5.42 0 0 1 36.28 10a5 5 0 0 1-.12 2.21c-.32 1.17-.64 2.38-.97 3.6ZM31.83 28.32c-.1.4-.2.79-.31 1.18-.26.94-.45 1.9-.79 2.8a5.42 5.42 0 0 1-4.29 3.6 5.55 5.55 0 0 1-2.53-.11c-1.06-.29-2.12-.56-3.19-.84l-.13-.05a17.57 17.57 0 0 0 11.24-6.58ZM15.68 1.08a17.6 17.6 0 0 0-11 6.12c.11-.39.21-.79.32-1.18a21.58 21.58 0 0 1 .7-2.56A5.44 5.44 0 0 1 10.2.05a6.31 6.31 0 0 1 2.69.28l2.56.67ZM7.34 31.43 4 30.54a5.05 5.05 0 0 1-1.66-.82A5.52 5.52 0 0 1 0 25.88a6.36 6.36 0 0 1 .27-2.6c.23-.8.44-1.6.65-2.4 0 0 0-.06.06-.15a16.91 16.91 0 0 0 2.17 5.94 18 18 0 0 0 4.19 4.76Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgAparat)
export default ForwardRef
