import * as React from "react"
import { forwardRef } from "react"

const SvgHydravit = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 211.91 50.31"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".hydravit_svg__cls-1{fill:#3c7bb6}"}</style>
    </defs>
    <g id="hydravit_svg__Layer_2" data-name="Layer 2">
      <g id="hydravit_svg__Layer_1-2" data-name="Layer 1">
        <path
          className="hydravit_svg__cls-1"
          d="M25.13 19.64H6.05V2.27H0v41.01h6.05V25.22h19.08v18.06h6.06V2.27h-6.06v17.37zM43.91 47.61a6.94 6.94 0 0 1-1.85 2.7h6.66a24.4 24.4 0 0 0 1.41-2.94l13.39-33.78h-6.23L49.11 35.8 41.3 13.59h-6.56l11.32 28.92ZM85.69 16.17a10 10 0 0 0-7.89-3.24 12.39 12.39 0 0 0-10 4.47c-2.45 2.94-3.7 6.87-3.7 11.7 0 4.51 1.13 8.15 3.36 10.8a11.3 11.3 0 0 0 9.1 4.1 11.19 11.19 0 0 0 9.13-4.06v3.39h5.94V0h-5.94Zm-2.07 4.14a7.34 7.34 0 0 1 2.07 5.31v4.17a9.51 9.51 0 0 1-2.2 6.44 7 7 0 0 1-5.6 2.49 6.71 6.71 0 0 1-5.57-2.57 11.32 11.32 0 0 1-2.12-7.27c0-3.4.75-6.07 2.22-8a7.11 7.11 0 0 1 6-2.77 6.89 6.89 0 0 1 5.2 2.2ZM111.85 13.09a7.45 7.45 0 0 0-4.9 1.84 9.31 9.31 0 0 0-1.95 2.21v-3.55h-5.9v29.69h5.9V28.16a12.05 12.05 0 0 1 1.82-7 5.06 5.06 0 0 1 4.27-2.43 4.86 4.86 0 0 1 3 .76l1.14.87v-6.6l-.44-.18a7.84 7.84 0 0 0-2.94-.49ZM129.46 12.93a18.25 18.25 0 0 0-10 2.76l-.33.21v6.55l1.17-1a13.34 13.34 0 0 1 8.77-3.31c2.11 0 4.9.63 5.1 5.89l-7.85 1.1c-6.6.92-10 4.26-10 9.92a8.47 8.47 0 0 0 2.68 6.47 11.1 11.1 0 0 0 15.2-1.26v3h5.94V24.18c0-7.47-3.59-11.25-10.68-11.25Zm4.74 16.15v2a7.68 7.68 0 0 1-2 5.49 6.54 6.54 0 0 1-5 2.14 5 5 0 0 1-3.46-1.13 3.6 3.6 0 0 1-1.24-2.86c0-1.66.42-2.77 1.3-3.38a10.44 10.44 0 0 1 4.44-1.44ZM157 34.72c-.11.3-.21.62-.3 1-.11-.35-.23-.7-.36-1.06l-7.34-21h-6.5l11.25 29.69h5.42L171 13.59h-6.32ZM174.25 1.57a3.54 3.54 0 0 0-1.07 2.62 3.47 3.47 0 0 0 1.07 2.6 3.72 3.72 0 0 0 5.17 0 3.47 3.47 0 0 0 1.08-2.57 3.48 3.48 0 0 0-1.08-2.62 3.74 3.74 0 0 0-5.17-.03ZM173.79 13.59h5.94v29.69h-5.94zM201.74 18.87v-5.28h-7.13V5l-5.94 1.92v6.71h-4.86v5.28h4.86v16c0 5.88 2.81 9 8.13 9a9.5 9.5 0 0 0 4.57-1l.37-.2V37l-1.14.86a4.07 4.07 0 0 1-2.53.8 3.23 3.23 0 0 1-2.65-1 6.11 6.11 0 0 1-.81-3.62V18.87ZM206.34 2.62h-1.43V6.5h-1V2.62h-1.41v-.77h3.8ZM208.26 1.85l1.2 3.37 1.19-3.37h1.26V6.5h-1V5.23L211 3l-1.22 3.5h-.66L207.87 3l.1 2.19V6.5h-1V1.85Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgHydravit)
export default ForwardRef
