import * as React from "react"
import { forwardRef } from "react"

const SvgAxon = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 113.39 58.25"
    ref={ref}
    {...props}
  >
    <defs>
      <style>
        {
          ".axon_svg__cls-1{fill:#58595b}.axon_svg__cls-2{fill:#ffca05}.axon_svg__cls-3{fill:#ffcb05}.axon_svg__cls-4{fill:#454547}"
        }
      </style>
    </defs>
    <g id="axon_svg__Layer_2" data-name="Layer 2">
      <g id="axon_svg__Layer_1-2" data-name="Layer 1">
        <path
          className="axon_svg__cls-1"
          d="M6.5 29.16H0L12.18 0l17.15 29.1H8.72l2.17-5.28h8.72l-6.26-10.34L6.5 29.16zM29.98 29.05l10.01-13.76L27.63.9h7.79l8.26 9.83L51.17.79h7.67L47.66 15.4l11.47 13.59h-7.31l-7.85-8.93-6.96 9.1-7.03-.11zM113.39 21.27 89.22.81v8.91l24.17 19.5v-7.95z"
        />
        <path
          className="axon_svg__cls-2"
          d="M71.59 1.15c-8.11 0-14.7 6.22-14.7 13.9S63.48 29 71.59 29s14.7-6.23 14.7-13.91-6.58-13.94-14.7-13.94Zm0 22.36a8.6 8.6 0 0 1-8.72-8.46 8.73 8.73 0 0 1 17.45 0 8.6 8.6 0 0 1-8.73 8.46Z"
        />
        <path
          className="axon_svg__cls-2"
          d="M71.75 17.38a2.11 2.11 0 1 0-2.12-2.11 2.12 2.12 0 0 0 2.12 2.11Z"
        />
        <path
          className="axon_svg__cls-1"
          d="M89.22 29.13h7.65V18.19l-7.65-6.28v17.22zM113.38.86h-7.65v11.61l7.65 6.48V.86z"
        />
        <path
          className="axon_svg__cls-3"
          d="M33.63 52.05h1.06L37 58.21h-1.43l-.44-1.12h-2l-.46 1.12h-1.4Zm1 3.73-.5-1.56-.53 1.56ZM40 55.17 37.74 52h1.6l1.48 2 1.45-2h1.66l-2.28 3.22 2.19 3h-1.73l-1.29-1.88-1.28 1.88H37.8Z"
        />
        <path
          className="axon_svg__cls-3"
          d="M45.58 57.82a3.1 3.1 0 0 1-1.14-1.16 3.24 3.24 0 0 1 0-3.19 3.1 3.1 0 0 1 1.14-1.16 3 3 0 0 1 3.12 0 3.1 3.1 0 0 1 1.14 1.16 3.24 3.24 0 0 1 0 3.19 3.1 3.1 0 0 1-1.14 1.16 3 3 0 0 1-1.56.43 3.05 3.05 0 0 1-1.56-.43ZM48 56.75a1.64 1.64 0 0 0 .6-.7 2.18 2.18 0 0 0 .22-1 2.08 2.08 0 0 0-.45-1.41 1.69 1.69 0 0 0-2.44 0 2.08 2.08 0 0 0-.45 1.41 2.32 2.32 0 0 0 .22 1 1.64 1.64 0 0 0 .6.7 1.57 1.57 0 0 0 1.7 0ZM51 52h1.44l2.19 3.81V52H56v6.26h-1.5l-2.14-3.76v3.76H51ZM60.51 52a9.74 9.74 0 0 1 1.31-.11 3.86 3.86 0 0 1 2.17.55 2.33 2.33 0 0 1 1 1.67 2.29 2.29 0 0 1-.2 1.37 2.34 2.34 0 0 1-.65.82 2.57 2.57 0 0 1-.87.45 3.12 3.12 0 0 1-1 .16h-.36v1.35h-1.4Zm1.76 3.59a1.58 1.58 0 0 0 1-.3.94.94 0 0 0 .39-.79s0-.1 0-.2a1.32 1.32 0 0 0-.41-.82 1.29 1.29 0 0 0-.89-.29 1.84 1.84 0 0 0-.41 0v2.33a2.07 2.07 0 0 0 .32.04ZM67.21 52.05h1.07l2.29 6.16h-1.42l-.43-1.12h-2l-.45 1.12h-1.43Zm1 3.73-.5-1.56-.53 1.56ZM72.39 57.79a3.2 3.2 0 0 1-1.19-1.15 3 3 0 0 1 0-3.13 3.13 3.13 0 0 1 1.19-1.15 3.24 3.24 0 0 1 1.61-.43 2.49 2.49 0 0 1 1.38.36 3.54 3.54 0 0 1 1 1.06l-1 .69a1.8 1.8 0 0 0-.62-.58 1.52 1.52 0 0 0-.75-.21 1.94 1.94 0 0 0-1.64.91 1.71 1.71 0 0 0-.25.91 1.68 1.68 0 0 0 .25.91 1.94 1.94 0 0 0 1.64.91 2 2 0 0 0 1.5-.74l.93.68a2.92 2.92 0 0 1-1 1.05 2.84 2.84 0 0 1-1.42.33 3.34 3.34 0 0 1-1.63-.42ZM77.21 53.05l.13-1.05h1.28v2.1l1.75-2.1h1.76l-2.25 2.68 2.21 3.57h-1.53L79 55.69l-.38.46v2.06h-1.41Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M73 45.64a5.74 5.74 0 0 1-.33 1.63 3.62 3.62 0 0 1-1.2.85 4.8 4.8 0 0 1-2 .44 2.34 2.34 0 0 1-1.87-.67 1.6 1.6 0 0 1-.36-1.06 4.6 4.6 0 0 1 1.08-2.39h.06a.28.28 0 0 1 .3.2 2.83 2.83 0 0 0-.56 1.48 1 1 0 0 0 .51.89 2 2 0 0 0 1.07.25 4.76 4.76 0 0 0 1.72-.31 2 2 0 0 0 1-.6 3.21 3.21 0 0 0-.92-1.78l.5-1.33a4.33 4.33 0 0 1 .67 1.1 3.18 3.18 0 0 1 .33 1.3Zm-1.88-2-1.09 1-1.06-1 1.1-1Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M69.44 48.76a2.49 2.49 0 0 1-2-.75 1.78 1.78 0 0 1-.4-1.18 4.71 4.71 0 0 1 1.11-2.5v-.06h.19a.49.49 0 0 1 .5.34v.16a2.71 2.71 0 0 0-.54 1.38.79.79 0 0 0 .41.72 1.88 1.88 0 0 0 1 .22 4.76 4.76 0 0 0 1.64-.29 2.2 2.2 0 0 0 .82-.47 2.93 2.93 0 0 0-.85-1.58l-.1-.1.73-1.75.22.25a4.28 4.28 0 0 1 .71 1.15 3.41 3.41 0 0 1 .36 1.38 5.86 5.86 0 0 1-.34 1.69v.07a3.89 3.89 0 0 1-1.27.9 5.07 5.07 0 0 1-2.19.42Zm-1-4.12a4.27 4.27 0 0 0-1 2.19 1.42 1.42 0 0 0 .31.94 2.15 2.15 0 0 0 1.71.59 4.53 4.53 0 0 0 1.93-.42 3.52 3.52 0 0 0 1.11-.77 5.59 5.59 0 0 0 .3-1.53 3.2 3.2 0 0 0-.32-1.23 5 5 0 0 0-.43-.77l-.36.88a3.58 3.58 0 0 1 .89 1.78v.15a2.2 2.2 0 0 1-1.06.68 5.15 5.15 0 0 1-1.79.32 2.24 2.24 0 0 1-1.18-.28 1.15 1.15 0 0 1-.61-1.06 2.87 2.87 0 0 1 .52-1.47ZM70 45l-1.36-1.3 1.41-1.25 1.34 1.3Zm-.76-1.29.77.74.79-.71-.74-.74ZM77.44 45.79h-.65a5.59 5.59 0 0 1-1.47 2.79 3.27 3.27 0 0 1-1-.19 3.58 3.58 0 0 1-.94-.41 6.88 6.88 0 0 0 1.84-1 4 4 0 0 0 1-1.21 3.57 3.57 0 0 1-2.22-.43 3.4 3.4 0 0 1 .49-1.85 2.13 2.13 0 0 1 .75-.72 1.26 1.26 0 0 1 .91.38 2.22 2.22 0 0 1 .63 1.32h.65Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M75.41 48.78h-.09a3.66 3.66 0 0 1-1.08-.2 4.19 4.19 0 0 1-1-.43l-.33-.21.37-.14a6.87 6.87 0 0 0 1.78-.95 3.75 3.75 0 0 0 .76-.86 3.16 3.16 0 0 1-2-.51l-.06-.06v-.08a3.48 3.48 0 0 1 .52-2 2.26 2.26 0 0 1 .83-.8h.09a1.53 1.53 0 0 1 1.06.44 2.32 2.32 0 0 1 .74 1.29h.68V46H77a6 6 0 0 1-1.5 2.72ZM73.83 48a5.41 5.41 0 0 0 .53.19 3.68 3.68 0 0 0 .86.18 5.29 5.29 0 0 0 1.37-2.62v-.17h.62v-.93h-.62v-.16a2.06 2.06 0 0 0-.59-1.2 1.1 1.1 0 0 0-.71-.32 1.8 1.8 0 0 0-.63.63 3.07 3.07 0 0 0-.46 1.64 3.55 3.55 0 0 0 2 .35h.34l-.16.3a4.4 4.4 0 0 1-1 1.27 6.77 6.77 0 0 1-1.55.84Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M84.84 45.8h-.44a1.08 1.08 0 0 1-.77-.35 1.49 1.49 0 0 1-.38-.75 1.47 1.47 0 0 1-.4.73 1.23 1.23 0 0 1-.88.37c-.53 0-.88-.33-1-1q-.42 1-1.14 1a1 1 0 0 1-.74-.34 1.52 1.52 0 0 1-.31-.59 1.32 1.32 0 0 1-1.2.93h-.42v-1.32h.5a.82.82 0 0 0 .73-.43 1.39 1.39 0 0 0 .22-.78h.46c0 .81.27 1.21.8 1.21a.82.82 0 0 0 .78-.47 1.5 1.5 0 0 0 .17-.74h.46a2.26 2.26 0 0 0 .11.81.64.64 0 0 0 .66.4.9.9 0 0 0 .83-.47 1.44 1.44 0 0 0 .2-.74h.45c0 .81.29 1.21.88 1.21h.45Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M85 46h-.6a1.25 1.25 0 0 1-.92-.42 1.91 1.91 0 0 1-.24-.32 2.34 2.34 0 0 1-.24.31 1.44 1.44 0 0 1-1 .43 1.13 1.13 0 0 1-1.06-.67 1.3 1.3 0 0 1-1.1.67 1.15 1.15 0 0 1-.89-.41 2.35 2.35 0 0 1-.19-.25 1.35 1.35 0 0 1-1.17.66h-.63v-1.72h.71a.62.62 0 0 0 .53-.28 1.34 1.34 0 0 0 .19-.68v-.2h.87v.2c0 1 .43 1 .59 1a.61.61 0 0 0 .6-.36 1.38 1.38 0 0 0 .15-.65v-.2h.87v.2a2.1 2.1 0 0 0 .09.74.45.45 0 0 0 .47.27.69.69 0 0 0 .65-.37 1.18 1.18 0 0 0 .17-.64v-.2h.87v.2c0 1 .48 1 .67 1H85Zm-1.78-2.39.19 1.06a1.15 1.15 0 0 0 .34.64.82.82 0 0 0 .61.29h.23v-.92h-.24c-.47 0-1-.22-1.08-1.21a1.63 1.63 0 0 1-.22.64 1.12 1.12 0 0 1-1 .57.85.85 0 0 1-.85-.52 2.16 2.16 0 0 1-.13-.69 1.64 1.64 0 0 1-.18.63 1 1 0 0 1-1 .58c-.43 0-.94-.22-1-1.21h-.06a1.73 1.73 0 0 1-.24.69 1 1 0 0 1-.91.52h-.29v.92h.21c.43 0 .76-.27 1-.81l.23-.53.16.56a1.14 1.14 0 0 0 .27.51.74.74 0 0 0 .58.27c.39 0 .7-.28.94-.87l.26-.6.14.63c.17.75.55.84.82.84a1 1 0 0 0 .73-.31 1.22 1.22 0 0 0 .34-.63Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M88.31 40.51a20.46 20.46 0 0 0-3 1.13 3.16 3.16 0 0 1 1.91 1.4 2.44 2.44 0 0 1 0 .27 5.58 5.58 0 0 1-.22 1.62 2.83 2.83 0 0 1-2.17.86h-.41v-1.32h.43a9.18 9.18 0 0 0 1 0 2 2 0 0 0 .88-.36 3.34 3.34 0 0 0-2-1.55 11.83 11.83 0 0 1 .18-1.72 21 21 0 0 1 1.95-.9c.57-.23 1.05-.41 1.43-.52Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M84.85 46h-.62v-1.73h.64a8.35 8.35 0 0 0 .94 0 1.52 1.52 0 0 0 .64-.22 3.34 3.34 0 0 0-1.77-1.31l-.14-.05v-.14a14 14 0 0 1 .17-1.74v-.1h.08a21.57 21.57 0 0 1 2-.91 13.82 13.82 0 0 1 1.45-.53l.27-.08v1.59h-.09a21.57 21.57 0 0 0-2.51.92 3.1 3.1 0 0 1 1.5 1.3v.37a5.59 5.59 0 0 1-.19 1.63v.08a3 3 0 0 1-2.37.92Zm-.21-.4h.21a2.6 2.6 0 0 0 2-.76 5.3 5.3 0 0 0 .2-1.48v-.22a3 3 0 0 0-1.77-1.26l-.28-.09v.61a3.45 3.45 0 0 1 1.91 1.6l.06.14-.12.1a2.07 2.07 0 0 1-1 .39 9.78 9.78 0 0 1-1 0h-.23Zm.47-4.65c0 .2-.06.42-.08.66l.2-.1a20.71 20.71 0 0 1 2.87-1.1v-.74c-.32.11-.7.26-1.14.43-.72.29-1.34.57-1.85.84ZM90.77 38.57a9 9 0 0 1 .13 1.78 26.83 26.83 0 0 1-.36 4.18l-.51.73v-1.82a12.76 12.76 0 0 0-.45-3.87Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M89.82 45.92v-2.47a12.83 12.83 0 0 0-.43-3.82v-.12l1.51-1.31.07.33a9.36 9.36 0 0 1 .14 1.82 27.28 27.28 0 0 1-.37 4.21v.08Zm0-6.28a13.46 13.46 0 0 1 .42 3.8v1.17l.11-.15a27 27 0 0 0 .35-4.11 10.65 10.65 0 0 0-.08-1.35ZM27.19 43.62a1.64 1.64 0 0 0-.54-.1c-.57 0-1 .39-1.37 1.18 0 .11.21.22.57.32l1 .27c.36.14.53.3.53.49a5.16 5.16 0 0 1-.43 1.79 7.48 7.48 0 0 1-3.79 1.06 2.29 2.29 0 0 1-1.41-.39 1.54 1.54 0 0 1-.58-1.24 3.92 3.92 0 0 1 .27-1.29 6.26 6.26 0 0 1 .56-1.15.36.36 0 0 1 .38.17 2.9 2.9 0 0 0-.4 1.43c0 .78.53 1.17 1.6 1.17a7.5 7.5 0 0 0 1.64-.22 5.35 5.35 0 0 0 1.57-.55 3.84 3.84 0 0 0-1.3-.47 1.39 1.39 0 0 1-.92-.47 4.75 4.75 0 0 1 .2-1C25.22 43 26 42.19 27 42.19h.19Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M23.14 48.85a2.49 2.49 0 0 1-1.54-.44A1.71 1.71 0 0 1 21 47a4.21 4.21 0 0 1 .29-1.36 5.43 5.43 0 0 1 .55-1.16l.05-.09H22a.59.59 0 0 1 .57.27l.06.1-.06.1a2.68 2.68 0 0 0-.37 1.33c0 .42.14 1 1.39 1a7.64 7.64 0 0 0 1.59-.21 6.74 6.74 0 0 0 1.18-.36 3.87 3.87 0 0 0-.9-.27 1.69 1.69 0 0 1-1.06-.56v-.06a4.48 4.48 0 0 1 .21-1c.54-1.84 1.41-2.73 2.67-2.6h.18v1.72l-.08.16-.18-.06c-.69-.25-1.2 0-1.59.87a1.48 1.48 0 0 0 .39.15l1 .27c.46.17.68.4.68.68a5.59 5.59 0 0 1-.44 1.86v.07h-.06a7.8 7.8 0 0 1-4.04 1Zm-1.05-4.09a5.67 5.67 0 0 0-.45 1 3.87 3.87 0 0 0-.26 1.24 1.32 1.32 0 0 0 .48 1.12 2.09 2.09 0 0 0 1.28.35 7.31 7.31 0 0 0 3.62-1 6.09 6.09 0 0 0 .27-.83l-.16.1a5.66 5.66 0 0 1-1.63.57 8.08 8.08 0 0 1-1.69.23c-1.5 0-1.81-.74-1.81-1.37a3 3 0 0 1 .36-1.39Zm2.66.8a1.5 1.5 0 0 0 .76.34 4 4 0 0 1 1.37.5l.18.12a3.36 3.36 0 0 0 .09-.72c0-.1-.14-.21-.39-.3l-1-.27c-.32-.08-.68-.22-.72-.48v-.06c.44-1 1.09-1.43 1.9-1.26v-1c-.91 0-1.59.8-2 2.33a4.35 4.35 0 0 0-.19.8ZM32.74 46.05h-.44c-.46 0-.82-.35-1.07-1a5 5 0 0 1-.38.88 3.52 3.52 0 0 1-1.45.3 1.67 1.67 0 0 1-1.17-.33c0-.36.13-.87.24-1.52h.29a.47.47 0 0 0 .23.46 1 1 0 0 0 .56.11 6.59 6.59 0 0 0 .71-.07c.48-.07.74-.15.79-.25a4.94 4.94 0 0 0-.95-2l.79-1a2.62 2.62 0 0 1 .5 1.1l.34 1.35c.16.44.37.66.64.66h.37Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M29.4 46.38a1.88 1.88 0 0 1-1.32-.38l-.08-.08v-.09c.05-.37.13-.87.25-1.54v-.16H29v.2a.32.32 0 0 0 .14.3.84.84 0 0 0 .45.07 4.65 4.65 0 0 0 .68-.07 3.16 3.16 0 0 0 .58-.12 4.4 4.4 0 0 0-.87-1.77l-.11-.12 1-1.3.17.16a2.51 2.51 0 0 1 .56 1.2l.33 1.32c.09.23.23.51.44.51H33v1.72h-.7a1.2 1.2 0 0 1-1-.71c-.07.15-.14.29-.22.43L31 46h-.06a3.79 3.79 0 0 1-1.54.38Zm-.95-.61a1.68 1.68 0 0 0 .95.22 3.32 3.32 0 0 0 1.3-.26 4.68 4.68 0 0 0 .33-.79v-.11a2.45 2.45 0 0 1-.78.19 5.59 5.59 0 0 1-.73.08 1.31 1.31 0 0 1-.67-.13.7.7 0 0 1-.27-.29c-.04.44-.09.81-.13 1.09Zm2.77-1.41.2.57c.22.62.52.93.88.93h.24v-.93h-.17c-.36 0-.64-.27-.83-.79l-.36-1.37a3.2 3.2 0 0 0-.3-.82l-.52.65a5.33 5.33 0 0 1 .85 1.79Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M37.2 46h-1.42a1.06 1.06 0 0 1-.78-.3 1.32 1.32 0 0 1-.41-.72 1.24 1.24 0 0 1-.43.71 1.12 1.12 0 0 1-.77.35h-.86v-1.32h.81a.94.94 0 0 0 .81-.44 1.44 1.44 0 0 0 .23-.8h.43a1.47 1.47 0 0 0 .23.85.87.87 0 0 0 .78.39h1.38Zm-1.5-3.9-1.09 1-1.06-1 1.1-1Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M37.4 46.24h-1.62a1.26 1.26 0 0 1-.91-.4 2.16 2.16 0 0 1-.27-.33 1.37 1.37 0 0 1-.28.32 1.35 1.35 0 0 1-.91.41h-1.07v-1.72h1a.71.71 0 0 0 .63-.35 1.17 1.17 0 0 0 .21-.69v-.2h.33l-1.28-1.23 1.41-1.24L36 42.1l-1.31 1.18H35v.2a1.25 1.25 0 0 0 .19.74.68.68 0 0 0 .61.3h1.6Zm-2.81-2.42.22 1.12a1.17 1.17 0 0 0 .36.62.86.86 0 0 0 .61.29H37v-.93h-1.16a1.13 1.13 0 0 1-1-.48 1.63 1.63 0 0 1-.25-.76 1.6 1.6 0 0 1-.26.71 1.13 1.13 0 0 1-1 .53h-.6v.93h.65a1 1 0 0 0 .63-.3 1.08 1.08 0 0 0 .36-.6Zm-.74-1.75.77.73.79-.71-.76-.73Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M41.18 44a3.13 3.13 0 0 1-.48 1.4 2 2 0 0 1-1.6.68h-2l-.1-1.34h2.29c.73 0 1.09-.1 1.09-.31a2.46 2.46 0 0 0-.57-1l.78-1a2.49 2.49 0 0 1 .59 1.57Zm-.85 3.54-1.09 1-1.06-1 1.11-1Z"
        />
        <path
          className="axon_svg__cls-4"
          d="m39.24 48.77-1.36-1.31 1.39-1.22h-2.39l-.08-1.71h2.5c.79 0 .89-.12.9-.13a3.57 3.57 0 0 0-.54-.82l-.09-.12 1-1.35.16.21a2.68 2.68 0 0 1 .66 1.68 3.29 3.29 0 0 1-.53 1.49 2.12 2.12 0 0 1-1.54.75l1.31 1.27Zm-.76-1.3.77.75.75-.72-.75-.73Zm-1.21-1.62h1.83a1.76 1.76 0 0 0 1.44-.61A2.9 2.9 0 0 0 41 44a2.2 2.2 0 0 0-.38-1.18l-.51.67c.45.61.52.83.52 1 0 .45-.7.51-1.3.51h-2.1ZM48.61 46.05h-.41q-.87 0-1.2-1.41a2.52 2.52 0 0 1-.45 1 2.51 2.51 0 0 1-2.1-.68c.13-.49.26-.88.36-1.16a5.57 5.57 0 0 1 1.77-.94 4.11 4.11 0 0 0-.18-.52l.6-.87a3.08 3.08 0 0 1 .41 1.28c.12.8.2 1.26.23 1.37.13.43.34.64.64.64h.33Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M48.82 46.25h-.62a1.31 1.31 0 0 1-1.21-1 2.72 2.72 0 0 1-.27.44l-.06.08h-.11a2.76 2.76 0 0 1-2.27-.77h-.06v-.09a12.34 12.34 0 0 1 .37-1.18l.05-.08a5.57 5.57 0 0 1 1.66-.91 3.43 3.43 0 0 0-.12-.33v-.1l.82-1.23.18.24a3.28 3.28 0 0 1 .43 1.36c.16 1 .21 1.29.23 1.35.15.49.36.49.44.49h.54ZM47 43.81l.18.79c.28 1.25.81 1.25 1 1.25h.2v-.93h-.12c-.28 0-.64-.13-.84-.78 0-.08-.07-.31-.24-1.4a3.78 3.78 0 0 0-.22-.91l-.34.49a4.25 4.25 0 0 1 .14.46v.17l-.17.06a5.43 5.43 0 0 0-1.66.86c-.09.26-.2.59-.32 1a2.47 2.47 0 0 0 1.77.52 2.76 2.76 0 0 0 .37-.81Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M53.09 46h-1.41a1.08 1.08 0 0 1-.77-.34 1.37 1.37 0 0 1-.4-.72 1.29 1.29 0 0 1-.43.71 1.12 1.12 0 0 1-.77.35h-.86v-1.28h.81a.91.91 0 0 0 .8-.44 1.37 1.37 0 0 0 .24-.8h.43a1.4 1.4 0 0 0 .23.85.86.86 0 0 0 .77.39h1.36Zm-2.42-3.85-1.09 1-1.06-1 1.1-1Zm2.18 0-1.09 1-1.07-1 1.11-1Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M53.3 46.24h-1.62a1.26 1.26 0 0 1-.91-.4 1.4 1.4 0 0 1-.27-.33 1.65 1.65 0 0 1-.28.32 1.35 1.35 0 0 1-.91.41h-1.07v-1.72h1a.71.71 0 0 0 .63-.35 1.24 1.24 0 0 0 .2-.69v-.2h.85v.2a1.25 1.25 0 0 0 .19.74.68.68 0 0 0 .6.3h1.59Zm-2.82-2.42.23 1.12a1.22 1.22 0 0 0 .35.62.89.89 0 0 0 .62.29h1.21v-.93h-1.16a1.1 1.1 0 0 1-.95-.48 1.63 1.63 0 0 1-.25-.76 1.6 1.6 0 0 1-.26.71 1.14 1.14 0 0 1-1 .53h-.61v.93h.66a.92.92 0 0 0 .62-.3 1.1 1.1 0 0 0 .37-.6Zm1.27-.34-1.07-1-1.11 1-1.35-1.31 1.4-1.24 1.07 1 1.11-1 1.34 1.29ZM51 42.19l.77.74.79-.71-.76-.73Zm-2.17 0 .76.74.79-.71-.76-.73Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M60.53 46.05h-.44a1 1 0 0 1-.77-.35 1.42 1.42 0 0 1-.38-.75 1.43 1.43 0 0 1-.4.73 1.19 1.19 0 0 1-.87.37c-.54 0-.89-.33-1-1q-.42 1-1.14 1a1 1 0 0 1-.74-.33 1.56 1.56 0 0 1-.31-.6 1.32 1.32 0 0 1-1.2.93h-.42v-1.31h.5a.84.84 0 0 0 .73-.43 1.44 1.44 0 0 0 .22-.78h.46c0 .8.27 1.21.8 1.21a.81.81 0 0 0 .78-.48 1.46 1.46 0 0 0 .17-.73H57a2.26 2.26 0 0 0 .11.81.65.65 0 0 0 .66.4.9.9 0 0 0 .83-.47 1.47 1.47 0 0 0 .2-.74h.45c0 .8.29 1.21.88 1.21h.45Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M60.73 46.25h-.64a1.28 1.28 0 0 1-.92-.41 2.43 2.43 0 0 1-.24-.32 1.76 1.76 0 0 1-.24.3 1.4 1.4 0 0 1-1 .43 1.13 1.13 0 0 1-1.06-.67 1.28 1.28 0 0 1-1.1.67 1.14 1.14 0 0 1-.89-.4 2.48 2.48 0 0 1-.19-.26 1.35 1.35 0 0 1-1.17.66h-.62v-1.71h.7a.62.62 0 0 0 .55-.33 1.28 1.28 0 0 0 .2-.68v-.2H55v.2c0 1 .43 1 .59 1a.63.63 0 0 0 .6-.36 1.38 1.38 0 0 0 .15-.65v-.2h.87v.2a2 2 0 0 0 .09.73.45.45 0 0 0 .47.28.69.69 0 0 0 .65-.37 1.18 1.18 0 0 0 .17-.64v-.2h.87v.2c0 1 .48 1 .67 1h.65ZM59 43.87l.19 1.05a1.15 1.15 0 0 0 .34.65.86.86 0 0 0 .61.29h.23v-.92h-.24c-.47 0-1-.22-1.08-1.21a1.58 1.58 0 0 1-.22.63 1.11 1.11 0 0 1-1 .58.86.86 0 0 1-.85-.52 2 2 0 0 1-.12-.69h-.06a1.65 1.65 0 0 1-.18.62 1.05 1.05 0 0 1-1 .59c-.43 0-.94-.22-1-1.21h-.06a1.68 1.68 0 0 1-.24.68 1 1 0 0 1-.91.53H53v.92h.21c.43 0 .76-.27 1-.81l.23-.53.16.55a1.23 1.23 0 0 0 .27.52.74.74 0 0 0 .58.27c.39 0 .7-.29.94-.87l.26-.61.14.64c.17.75.55.84.82.84a1 1 0 0 0 .73-.31 1.26 1.26 0 0 0 .34-.63Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M64.51 44a3.11 3.11 0 0 1-.49 1.4 2 2 0 0 1-1.59.68h-2l-.06-1.31h2.29c.73 0 1.09-.1 1.09-.31a2.46 2.46 0 0 0-.57-1l.78-1a2.51 2.51 0 0 1 .55 1.54Zm-.86 3.54-1.09 1-1.06-1 1.11-1Z"
        />
        <path
          className="axon_svg__cls-4"
          d="m62.56 48.77-1.36-1.31 1.39-1.22H60.2l-.08-1.71h2.51c.78 0 .89-.12.89-.13a3.11 3.11 0 0 0-.53-.82l-.09-.12 1-1.35.17.21a2.66 2.66 0 0 1 .64 1.68 3.17 3.17 0 0 1-.53 1.49 2.12 2.12 0 0 1-1.54.75L64 47.51Zm-.76-1.3.77.75.79-.72-.76-.73Zm-1.2-1.62h1.83a1.74 1.74 0 0 0 1.43-.61A2.9 2.9 0 0 0 64.3 44a2.19 2.19 0 0 0-.37-1.18l-.52.67c.46.61.52.83.52 1 0 .45-.69.51-1.3.51h-2.07ZM92.21 37.14c0 .16-.23.33-.71.5a4.32 4.32 0 0 1-1 .23c-.38 0-.71 0-1 .05a1.64 1.64 0 0 0-.19.49c0 .16-.11.24-.17.24-.29 0-.43 0-.43-.13a4.08 4.08 0 0 1 .1-.87c.11-.44.25-.67.42-.67a2.47 2.47 0 0 1 .43.07 2.47 2.47 0 0 0 .43.07 4.71 4.71 0 0 0 2-.54 3 3 0 0 1 .12.56Z"
        />
        <path
          className="axon_svg__cls-4"
          d="M89.19 38.85c-.16 0-.64 0-.64-.33a4.41 4.41 0 0 1 .11-.91c.09-.35.24-.82.62-.82a2.27 2.27 0 0 1 .48.07 1.81 1.81 0 0 0 .38.06 4.56 4.56 0 0 0 2-.52l.23-.11.07.24a3.74 3.74 0 0 1 0 .61c0 .26-.26.47-.85.68a3.77 3.77 0 0 1-1 .25h-.88a1.23 1.23 0 0 0-.12.35c-.06.14-.14.43-.4.43Zm-.19-.41h.15v-.09a1.73 1.73 0 0 1 .22-.56l.06-.08h1.11a3.25 3.25 0 0 0 .89-.22c.51-.18.58-.3.59-.32a1.85 1.85 0 0 0 0-.25 4.65 4.65 0 0 1-1.86.44 2.24 2.24 0 0 1-.48-.08 1.88 1.88 0 0 0-.38-.06s-.11.06-.21.52a3.36 3.36 0 0 0-.09.7Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgAxon)
export default ForwardRef
