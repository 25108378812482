import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "src/components/organism/Header"
import Footer from "src/components/organism/Footer"
import {  theme } from "src/global-style/index.js"
import styled  from "@emotion/styled"

const Main = styled.main`
  margin-top:12vh ;
  ${theme.breakpoint.md}{
    margin-top: 8vh;
  }
`

const WrapPageElement = ({ children ,theme3}) => {
  const mainRef = React.useRef()
  const [canvasOpen , setCanvasOpen] = React.useState(false);
  const data = useStaticQuery(graphql`
      query SiteTitleQuery2 {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} canvasOpen={canvasOpen} setCanvasOpen={setCanvasOpen}/>
      <Main dir="rtl" ref={mainRef} >
        {children}
      </Main>
      <Footer />
    </>
  )
}

WrapPageElement.propTypes = {
  children: PropTypes.node.isRequired
}

export default WrapPageElement