import * as React from "react"
import { forwardRef } from "react"

const SvgInstagram = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35.02 35.02"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".instagram_svg__cls-1{fill:currentColor}"}</style>
    </defs>
    <g id="instagram_svg__Layer_2" data-name="Layer 2">
      <g id="instagram_svg__Layer_1-2" data-name="Layer 1">
        <path
          className="instagram_svg__cls-1"
          d="M25.05 35H10A10 10 0 0 1 0 25V10A10 10 0 0 1 10 0h15.05A10 10 0 0 1 35 10v15.05A10 10 0 0 1 25.05 35ZM10 3a7 7 0 0 0-7 7v15.05a7 7 0 0 0 7 7h15.05a7 7 0 0 0 7-7V10a7 7 0 0 0-7-7Z"
        />
        <path
          className="instagram_svg__cls-1"
          d="M17.51 26.48a9 9 0 1 1 9-9 9 9 0 0 1-9 9Zm0-14.93a6 6 0 1 0 6 6 6 6 0 0 0-6-6ZM29 8a2.16 2.16 0 1 1-2.16-2.15A2.16 2.16 0 0 1 29 8Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgInstagram)
export default ForwardRef
