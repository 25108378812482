import * as React from "react"
import { forwardRef } from "react"

const SvgYoutube = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.4 25.5"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{".youtube_svg__cls-1{fill:currentColor}"}</style>
    </defs>
    <g id="youtube_svg__Layer_2" data-name="Layer 2">
      <g id="youtube_svg__Layer_1-2" data-name="Layer 1">
        <path
          className="youtube_svg__cls-1"
          d="M36.4 19.08c-.06.31-.13.62-.2.93a7.09 7.09 0 0 1-6.77 5.47H7.29A7.17 7.17 0 0 1 0 18.35V7.16A7.09 7.09 0 0 1 7 0h22.1a7.15 7.15 0 0 1 7 5.28c.1.38.17.76.25 1.14Zm-18.21 4.28H29a6.5 6.5 0 0 0 1-.05 5 5 0 0 0 4.29-5.1V7.34a5 5 0 0 0-5.16-5.2H7.33a5 5 0 0 0-5.19 5.2v10.88a5.08 5.08 0 0 0 .12 1.16 5 5 0 0 0 5 4c3.66-.01 7.29-.02 10.93-.02Z"
        />
        <path
          className="youtube_svg__cls-1"
          d="M13.49 12.75V6.26A1.36 1.36 0 0 1 14 5.07a1.25 1.25 0 0 1 1.55.06L23 9.79c1 .65 2.05 1.28 3.07 1.94A1.23 1.23 0 0 1 26 14q-5.28 3.24-10.57 6.47a1.23 1.23 0 0 1-2-1.11q.06-3.36.06-6.61Z"
        />
      </g>
    </g>
  </svg>
)

const ForwardRef = forwardRef(SvgYoutube)
export default ForwardRef
