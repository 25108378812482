import * as React from "react"
import styled from "@emotion/styled"
import {  useTheme } from "@emotion/react"

const Root = styled.div`
  width: 50px;
  height: 50px;
  z-index: 20;
  ${props => props.theme.breakpoint.md}{
    width: 30px;
    height: 30px;
  }
  .hamburger-lines {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #fff;
    ${props => props.theme.breakpoint.lg}{
      height: 2px;
    }
  }

  .hamburger-lines .line1 {
    transform-origin: 0 0;
    transition: transform 0.3s ease-in-out;
  }

  .hamburger-lines .line2 {
    transition: transform 0.1s ease-in-out;
  }

  .hamburger-lines .line3 {
    transform-origin: 0 100%;
    transition: transform 0.3s ease-in-out;
  }

  .hamburger-lines.open .line1 {
    transform: rotate(45deg);
  }

  .hamburger-lines.open .line2 {
    transform: scaleY(0);
  }

  .hamburger-lines.open .line3 {
    transform: rotate(-45deg);
  }
`

const HamburgerIcon = ({ canvasOpen, setCanvasOpen, className }) => {
  const theme = useTheme()
  return (
    <>
      <Root theme={theme} className={className} canvasOpen={canvasOpen}
            onClick={() => {
              setCanvasOpen(value => !value)
            }}
      >
        <div className={canvasOpen ? "hamburger-lines open" : "hamburger-lines"}>
          <span className="line line1" />
          <span className="line line2" />
          <span className="line line3" />
        </div>
      </Root>
    </>
  )
}

export default HamburgerIcon